input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  -webkit-text-fill-color: #000;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
input:focus{
  outline:none;
}

textarea:focus {
  outline: none;
}
textarea { resize: none; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

*::-webkit-scrollbar {
  display: none;
  overscroll-behavior: "contain";
}
/* @media (max-width: 600px) {
  *::-webkit-scrollbar {
    display: none;
  }
} */


* {
  font-family: Pretendard;
  -ms-overflow-style: none;
  /* 인터넷 익스플로러 */
  scrollbar-width: none;
  /* 파이어폭스 */
  touch-action: pan-y;
  box-sizing: border-box;
}


html {
  /* scroll-behavior: smooth; */
  width: 100%;
  /* min-width: 1440px; */
  /* width: "100vw"; */
  /* overflow-x: hidden; */
}

body {
  margin: 0;
  box-sizing: border-box;
}


@media (max-width: 600px) {
  body {
    width: 100vw;
    min-width: 100vw;
    overflow-x: hidden;
    font-family: "Pretendard";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@media (min-width: 600px) {
  body {
    overflow-x: scroll;
    min-width: 1440px;
    font-family: "Pretendard";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

/* *:not(input.field) {    
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  align-items: center;
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: "SpoqaHanSansNeo-Regular";
  src: local("SpoqaHanSansNeo-Regular"),
    url("./assets/fonts/SpoqaHanSansNeo-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SpoqaHanSansNeo-Bold";
  src: local("SpoqaHanSansNeo-Bold"),
    url("./assets/fonts/SpoqaHanSansNeo-Bold.otf") format("opentype");
}

@font-face {
  font-family: "SpoqaHanSansNeo-Light";
  src: local("SpoqaHanSansNeo-Light"),
    url("./assets/fonts/SpoqaHanSansNeo-Light.otf") format("opentype");
}

@font-face {
  font-family: "SpoqaHanSansNeo-Medium";
  src: local("SpoqaHanSansNeo-Medium"),
    url("./assets/fonts/SpoqaHanSansNeo-Medium.otf") format("opentype");
}

@font-face {
  font-family: "SpoqaHanSansNeo-Thin";
  src: local("SpoqaHanSansNeo-Thin"),
    url("./assets/fonts/SpoqaHanSansNeo-Thin.otf") format("opentype");
}

@font-face {
  font-family: "Gmarket Sans";
  src: local("GmarketSans"),
    url("./assets/fonts/GmarketSansMedium.otf") format("opentype");
}

@font-face {
  font-family: "SB AggroOTF";
  src: local("SBAggroOTF"),
    url("./assets/fonts/SBAggroOTF_M.otf") format("opentype");
}

@font-face {
  font-family: "SB AggroOTF Light";
  src: local("SBAggroOTF_L"),
    url("./assets/fonts/SBAggroOTF_L.otf") format("opentype");
}

@font-face {
  font-family: "SB AggroOTF Medium";
  src: local("SBAggroOTF_M"),
    url("./assets/fonts/SBAggroOTF_M.otf") format("opentype");
}

@font-face {
  font-family: "NEXON Lv1 Gothic Low OTF";
  src: local("NEXON Lv1 Gothic Low OTF"),
    url("./assets/fonts/NEXON Lv1 Gothic Low OTF.woff") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  src: local("Pretendard-Regular"),
    url("./assets/fonts/Pretendard-Regular.woff") format("opentype");
}

@font-face {
  font-family: "Pretendard-title";
  src: local("Pretendard-Medium"),
    url("./assets/fonts/Pretendard-Medium.otf") format("opentype");
}

@font-face {
  font-family: "GmarketSans";
  src: local("GmarketSansMedium"),
    url("./assets/fonts/GmarketSansMedium.otf") format("opentype");
}

@font-face {
  font-family: "GmarketSansBold";
  src: local("GmarketSansBold"),
    url("./assets/fonts/GmarketSansBold.otf") format("opentype");
}



/* AI셀러 css  */

.pointer {
  cursor: pointer;
}

.progress {
  cursor: progress;
}

.disabledButton {
  opacity: 0.5;
  cursor: not-allowed;
}

.selectedButton {
  opacity: 0.5;
  cursor: pointer;
}

.pendingButton {
  opacity: 0.5;
  cursor: progress;
}

.button {
  cursor: pointer;
}

.button:hover {
  opacity: 0.7;
}

.pageWrapper {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.pageWrapperCenter {
  width: 100%;
  min-height: calc(100vh - 60px);
  /* overflow-y: scroll; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.h-space5 {
  border: none;
  background-color: none;
  width: 5px;
  height: 1px;
}

.h-space6 {
  border: none;
  background-color: none;
  width: 6px;
  height: 1px;
}

.h-space10 {
  border: none;
  background-color: none;
  width: 10px;
  height: 1px;
}

.h-space12 {
  border: none;
  background-color: none;
  width: 12px;
  height: 1px;
}

.h-space15 {
  border: none;
  background-color: none;
  width: 10px;
  height: 1px;
}

.h-space20 {
  border: none;
  background-color: none;
  width: 20px;
  height: 1px;
}

.h-space24 {
  border: none;
  background-color: none;
  width: 24px;
  height: 1px;
}

.h-space25 {
  border: none;
  background-color: none;
  width: 25px;
  height: 1px;
}

.h-space30 {
  border: none;
  background-color: none;
  width: 30px;
  height: 1px;
}

.h-space100 {
  border: none;
  background-color: none;
  width: 100px;
  height: 1px;
}

.h-space200 {
  border: none;
  background-color: none;
  width: 200px;
  height: 1px;
}

.v-space1 {
  border: none;
  background-color: none;
  width: 1px;
  height: 1px;
}

.v-space20 {
  border: none;
  background-color: none;
  width: 1px;
  height: 2px;
}

.v-space3 {
  border: none;
  background-color: none;
  width: 1px;
  height: 3px;
}

.v-space4 {
  border: none;
  background-color: none;
  width: 1px;
  height: 4px;
}

.v-space5 {
  border: none;
  background-color: none;
  width: 1px;
  height: 5px;
}

.v-space8 {
  border: none;
  background-color: none;
  width: 1px;
  height: 8px;
}

.v-space9 {
  border: none;
  background-color: none;
  width: 1px;
  height: 9px;
}

.v-space10 {
  border: none;
  background-color: none;
  width: 1px;
  height: 10px;
}

.v-space12 {
  border: none;
  background-color: none;
  width: 1px;
  height: 12px;
}

.v-space15 {
  border: none;
  background-color: none;
  width: 1px;
  height: 15px;
}

.v-space16 {
  border: none;
  background-color: none;
  width: 1px;
  height: 16px;
}

.v-space20 {
  border: none;
  background-color: none;
  width: 1px;
  height: 20px;
}

.v-space24 {
  border: none;
  background-color: none;
  width: 1px;
  height: 24px;
}

.v-space25 {
  border: none;
  background-color: none;
  width: 1px;
  height: 25px;
}

.v-space30 {
  border: none;
  background-color: none;
  width: 1px;
  height: 30px;
}

.v-space100 {
  border: none;
  background-color: none;
  width: 1px;
  height: 100px;
}

.v-space150 {
  border: none;
  background-color: none;
  width: 1px;
  height: 100px;
}

.v-space200 {
  border: none;
  background-color: none;
  width: 1px;
  height: 200px;
}

.flexRowWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexColumnWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textAlign {
  text-align: center;
}

.borderBox {
  border: 2px solid #444444;
}

.round100 {
  border-radius: 100px;
}

.round64 {
  border-radius: 64px;
}

.round50 {
  border-radius: 50px;
}

.round40 {
  border-radius: 40px;
}

.round32 {
  border-radius: 32px;
}

.round20 {
  border-radius: 20px;
}

.round16 {
  border-radius: 16px;
}

.round12 {
  border-radius: 12px;
}

.round8 {
  border-radius: 8px;
}

.round6 {
  border-radius: 6px;
}

.round5 {
  border-radius: 5px;
}

.round4 {
  border-radius: 4px;
}

.circle9 {
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.circle {
  border-radius: 50%;
}

.circle5 {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.borderCircle {
  border: 2px solid #444444;
  border-radius: 50%;
}

.absoluteBox {
  position: absolute;
}

.fixedBox {
  position: fixed;
}

.stickyBox {
  position: sticky;
}

.relativeBox {
  position: relative;
}

.paddingBox {
  padding: 16px;
}

.halfWidth {
  width: 50%;
}

.fullWidth {
  width: 100%;
}

.textPaddingBox {
  padding: 4px 12px;
}

.font40bold {
  font-size: 40px;
  font-weight: 700;
}

.font36bold {
  font-size: 36px;
  font-weight: 700;
}

.font30bold {
  font-size: 30px;
  font-weight: 700;
}

.font28bold {
  font-size: 25px;
  font-weight: 700;
}

.font26bold {
  font-size: 26px;
  font-weight: 700;
}

.font25bold {
  font-size: 25px;
  font-weight: 700;
}

.font24bold {
  font-size: 24px;
  font-weight: 700;
}

.font23bold {
  font-size: 23px;
  font-weight: 700;
}

.font22bold {
  font-size: 22px;
  font-weight: 700;
}

.font20bold {
  font-size: 20px;
  font-weight: 700;
}

.font17bold {
  font-size: 17px;
  font-weight: 700;
}

.font16bold {
  font-size: 16px;
  font-weight: 700;
}

.font18bold {
  font-size: 18px;
  font-weight: 700;
}

.font15bold {
  font-size: 15px;
  font-weight: 700;
}

.font14bold {
  font-size: 14px;
  font-weight: 700;
}

.font12bold {
  font-size: 12px;
  font-weight: 700;
}

.font10bold {
  font-size: 10px;
  font-weight: 700;
}

.font36middle {
  font-size: 36px;
  font-weight: 500;
}

.font30middle {
  font-size: 30px;
  font-weight: 500;
}

.font25middle {
  font-size: 25px;
  font-weight: 500;
}

.font24middle {
  font-size: 24px;
  font-weight: 500;
}

.font23middle {
  font-size: 23px;
  font-weight: 500;
}

.font22middle {
  font-size: 22px;
  font-weight: 500;
}

.font20middle {
  font-size: 20px;
  font-weight: 500;
}

.font18middle {
  font-size: 18px;
  font-weight: 500;
}

.font16middle {
  font-size: 16px;
  font-weight: 500;
}

.font15middle {
  font-size: 15px;
  font-weight: 500;
}

.font14middle {
  font-size: 14px;
  font-weight: 500;
}

.font10middle {
  font-size: 10px;
  font-weight: 500;
}

.font30small {
  font-size: 30px;
  font-weight: 400;
}

.font25small {
  font-size: 25px;
  font-weight: 400;
}

.font23small {
  font-size: 23px;
  font-weight: 400;
}

.font22small {
  font-size: 22px;
  font-weight: 400;
}

.font20small {
  font-size: 20px;
  font-weight: 400;
}

.font18small {
  font-size: 18px;
  font-weight: 400;
}

.font16small {
  font-size: 16px;
  font-weight: 400;
}

.font15small {
  font-size: 15px;
  font-weight: 400;
}

.font14small {
  font-size: 14px;
  font-weight: 400;
}

.font13small {
  font-size: 13px;
  font-weight: 400;
}

.font12small {
  font-size: 12px;
  font-weight: 400;
}


.font10small {
  font-size: 10px;
  font-weight: 400;
}


.lineHeight20 {
  line-height: 20px;
}

.lineHeight28 {
  line-height: 28px;
}

.lineHeight30 {
  line-height: 30px;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @font-face {
  font-family: "Pretendard";
  src: local("Pretendard-Regular"),
    url("./assets/fonts/Pretendard-Regular.woff") format("opentype");
} */




.myPageInnerWrapper {
  min-width: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.refundInnerWrapper {
  min-width: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textOverflow {
  text-align: center;
  /* width: 100%; */
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.letterCenter {
  letter-spacing: -6%;
}

.border1 {
  border-width: 1px;
  border-style: solid;
}

.border2 {
  border-width: 2px;
  border-style: solid;
}

.lightOrangeBackground {
  background: rgba(255, 145, 51, 0.1);
}

.orangeBorder {
  border-color: #FF9133;
}

.orangeColor {
  color: #FF9133;
}

.orangeBorder {
  border-color: #FF9133;
}

.orangeBackground {
  background-color: #FF9133;
}

.grayColor {
  color: #9A9A9A;
}

.lightGrayColor {
  color: #A7A7A7;
}

.grayBorder {
  border-color: #9A9A9A;
}

.lightGrayBorder {
  border-color: #A7A7A7;
}

.lightGrayBorder {
  background-color: #A7A7A7;
}

.grayBackground {
  background-color: #9A9A9A;
}

.greenColor {
  color: #3CCC87;
}

.whiteColor {
  color: #FFFFFF;
}

.whiteBackground {
  background-color: #FFFFFF;
}

.whiteBorder {
  border-color: #FFFFFF;
}

.greenBackground {
  background-color: #3CCC87;
}

.letterSpace {
  letter-spacing: -0.04em;
}

.textWithImage {
  letter-spacing: -6%;
}

.rowLeft {
  justify-content: flex-start;
}

.rowRight {
  justify-content: flex-end;
}

.rowCenter {
  justify-content: center;
}

.rowRight {
  justify-content: flex-end;
}

.columnLeft {
  align-items: flex-start;
}

.columnCenter {
  align-items: center;
}

.columnRight {
  align-items: flex-end;
}

.flex {
  display: flex;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.shadow {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.lightShadow {
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
}

.shadowOrange {
  box-shadow: 0px 0px 8px #FF913333;
}

.customScroll {}

.hoverGrayBackground:hover {
  background-color: #9A9A9A;
}

.hoverOrangeBackground:hover {
  background-color: #FF9133;
}

.scale60 {
  transform: scale(0.6);
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyCenter {
  justify-content: center;
}

.alignStart {
  align-items: flex-start;
}

.alignEnd {
  align-items: flex-end;
}

.alignCenter {
  align-items: center;
}

.width1300 {
  width: 1300px;
}

.display-linebreak {
  white-space: pre-line;
}

.textInputNoMargin::placeholder {
  font-size: 14px;
  font-family: "Pretendard";
}

.textInputWithoutBorder::placeholder {
  font-size: 14px;
  font-family: "Pretendard";
}

@keyframes bannermove {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

@keyframes bannermovereverse {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(50%);
  }
}

@keyframes carousel {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% / 4));
  }
}

@keyframes carousel346 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-346px);
  }
}

@keyframes carousel693 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-693px);
  }
}
@keyframes carousel438 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-438px);
  }
}

.eventPhoneNumberInput {
  color: #A5A5A5;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  background-color: #F4F4F4;
}

.eventPhoneNumberInput:focus {
  outline: none;
  color: #111111;
}

.enabledSubmitButton {
  width: 220px;
  height: 48px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  color: #FFFFFF;
  background-color: #FF7F00;
  cursor: pointer;
}

.disabledSubmitButton {
  width: 220px;
  height: 48px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  color: #FFFFFF;
  background-color: #DFDFDF;
}

.registerPhoneNumberInput::placeholder {
  color: #C8C8C9
}


.imageBackdropFilter15 {
  filter: blur(15px);
}

.backdropFilter15 {
  backdrop-filter: blur(15px);
}

.backdropFilter27 {
  backdrop-filter: blur(27px);
}

.touchScroll {
  -webkit-overflow-scrolling: touch;
}

.hoverScale:hover {
  transform: scale(1.03);
  padding-left: 16px;
}

.contactInputBox::placeholder {
  color: #C8C8C8;
}

.contactInputBox:focus {
  border-color: #FF7F00;
}

.contactInputBox {
  border: 1px solid #DFDFDF;
  border-color: #DFDFDF;
}
