.textInputNoMargin {
    height: 44px;
    outline: none;
    /* border: 1px solid #F5F5F5; */
    border: 1px solid #D9D9D9;
    border-radius: 8;
    font-size: 16px;
    font-weight: 400;
    color: #111111;
    padding: 0px 16px;
    /* margin-bottom: 12px; */
    width: calc(100%);
    border-radius: 8px;
    /* margin: 0px 36px; */
}

.textInputNoMargin:focus{
    outline: none;
}

.textInputNoMargin::placeholder{
    color: #9D9DA5;
}

.textInputWithoutBorder{
    height: 44px;
    outline: none;
    /* border: 1px solid #F5F5F5; */
    border: none;
    border-radius: 8;
    font-size: 16px;
    font-weight: 400;
    color: #111111;
    padding: 0px 20px;
    margin-bottom: 12px;
    width: calc(100% - 36px);
    border-radius: 8px;
}

.textInputWithoutBorder::placeholder{
    color: #9D9DA5;
}

.textInput {
    height: 44px;
    outline: none;
    /* border: 1px solid #F5F5F5; */
    border: 1px solid #D9D9D9;
    border-radius: 8;
    font-size: 16px;
    font-weight: 400;
    color: #111111;
    padding: 0px 16px;
    margin-bottom: 12px;
    width: calc(100% - 36px);
    border-radius: 8px;
    /* margin: 0px 36px; */
}



.textInput::placeholder{
    color: #9D9DA5;
}

.cancelButton {
    bottom: 32px;
    cursor: pointer;
    width: 260px;
    height: 52px;
    border-radius: 32px;
    background-color: #FF9133;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    margin-top: 24px;
}

.registerButton {
    bottom: 32px;
    cursor: pointer;
    width: 260px;
    height: 52px;
    border-radius: 32px;
    background-color: #FF9133;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    margin-top: 24px;
}


.nonScrollBar {
    scroll-behavior: smooth;
    /* overscroll-behavior: contain; */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.nonScrollBar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}
